import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { GET, MERCHANT, POST, WITHDRAWBROKER, WITHDRAWLISTBROKER } from '../../service/service';
// import { SearchButton } from '../../Theme.jsx';
import TableData from './TableData';
import dayjs from 'dayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { SearchTextIcon } from '../../untils/icons.js'
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAPI } from "../../services/happypay-api";

function SearchWithdraw() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const api = useAPI();
    const [startDate, setStartDate] = useState(moment().startOf("month").tz(userTimeZone));
    const [endDate, setEndDate] = useState(moment().endOf("day").tz(userTimeZone));
    const [withdrawlist, setWithdrawlist] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);


    const WithdrawListBroker = async () => {
        try {
            const res = await api.WithdrawMerchant();
            if (res.success) {

                const sortedList = res.result.sort((a, b) =>
                    dayjs(b.date_created).valueOf() - dayjs(a.date_created).valueOf()
                );

                setWithdrawlist(sortedList);
                setPage(0);
            } else {
                alert(res.message);
            }
        } catch (error) {
            alert("Error fetching withdrawals: " + error.message);
        }
    };
    
    // const WithdrawListBroker = async (startDate, endDate) => {
    //     try {
    //         const res = await api.WithdrawMerchant(startDate, endDate);
    //         if (res.success) {

    //             const sortedList = res.result.sort((a, b) =>
    //                 dayjs(b.date_created).valueOf() - dayjs(a.date_created).valueOf()
    //             );

    //             setWithdrawlist(sortedList);
    //             setPage(0);
    //         } else {
    //             alert(res.message);
    //         }
    //     } catch (error) {
    //         alert("Error fetching withdrawals: " + error.message);
    //     }
    // };

    const handleChangeStartDate = (startValue) => {
        if (startValue) {
            if (startValue.isAfter(endDate)) {
                setEndDate(startValue.add(1, "second"));
            } else if (startValue.isSame(endDate)) {
                setEndDate(startValue.add(1, "second"));
            }
            setStartDate(startValue);
        }
    };

    const handleChangeEndDate = (endValue) => {
        if (endValue) {
            if (endValue.isBefore(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            } else if (endValue.isSame(startDate)) {
                setStartDate(endValue.subtract(1, "second"));
            }
            setEndDate(endValue);
        }
    };

    useEffect(() => {
        WithdrawListBroker(startDate, endDate);
    }, [startDate, endDate]);

    return (
        <>
            {/* <Grid container spacing={2} sx={{ marginTop: 2 }}> */}
                {/* <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <InputLabel>ร้านค้า</InputLabel>
                        <Select
                            value={merchantsearch}
                            onChange={(e) => setMerchantsearch(e.target.value)}
                            label="ร้านค้า"
                        >
                            <MenuItem value={0}>All</MenuItem>
                            {merchantList.map((item) => (
                                <MenuItem key={item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} sm={2.5}>
                        <DesktopDatePicker
                            label="Start"
                            inputFormat="DD/MMM/YYYY HH:mm:ss"
                            value={startDate}
                            onChange={handleChangeStartDate}
                            maxDate={dayjs()}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                        <DesktopDatePicker
                            label="End"
                            inputFormat="DD/MMM/YYYY HH:mm:ss"
                            value={endDate}
                            onChange={handleChangeEndDate}
                            minDate={startDate}
                            maxDate={dayjs()}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </LocalizationProvider>
            </Grid> */}
            <TableData
                withdrawlist={withdrawlist}
                startDate={startDate}
                endDate={endDate}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
}

export default SearchWithdraw;