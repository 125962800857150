import React from 'react';
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";

// components
import WithdrawMerchant from "../components/withdrawMerchant/SearchWithdraw";

function WithdrawMerchantPage() {
    return (
        <>
            <Stack spacing={2}>
                <Box>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Typography variant="h4" component="h2">
                                Withdraws Merchent
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Paper sx={{ p: 2 }}>
                        <WithdrawMerchant />
                    </Paper>
                </Box>
            </Stack>
        </>
    );
}

export default WithdrawMerchantPage;